@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
* {
    margin: 0;
    padding: 0;
    font-size: 100%;
}

body {
    font-family: 'Trebuchet MS', 'Source Sans Pro', serif;
    background-color: #FFFFFF;
    padding-top: 160px;

}

h1 {
    font-size: 30px;
    font-family: 'Trebuchet MS', 'Source Sans Pro', serif;
    color: #1d5980;
    font-weight: bold;
    padding-top: 0.5em;
    margin-left: 5%;
    margin-top: 0;
}

h2 {
    font-size: 16px;
    padding-top: 1em;
    margin-left: 5%;
    margin-top: 0;
    color: #1D5980;
}

a {
    color: inherit;
    font-size: 18px;
}

a.rounded {
    padding: 0.3em 1.2em;
    margin: 0 0.1em 0.1em 0;
    border: 0.16em solid rgba(255, 255, 255, 0);
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    color: #FFFFFF;
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    text-align: center;
    transition: all 0.2s;
}

a.rounded:hover {
    border-color: rgba(255, 255, 255, 1);
}

hr {
    background-color: #1D5980;
    width: 60%;
    margin: 1em auto;
    height: 1px;
    border: none;
}

.centerButton-wrapper {
    margin: auto;
    text-align: center;
    padding: 1em;

}

.centerButton {
    margin: auto;
    background-color: #58130E;
}

@media all and (max-width: 30em) {
    a.resp {
        display: block;
        margin: 0.2em auto;
    }
}

.header {
    background-color: #1D5980;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .1);
    position: fixed;
    width: 100%;
    z-index: 3;
    color: white;
    font-size: 20px;
    top: 116px;
}

.header ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow: hidden;
    background-color: #1D5980;
}

.header li a {
    display: block;
    text-decoration: none;
    color: inherit;
    padding: 7px
}

.header li a:hover,
.header .menu-btn:hover {
    background-color: #EE2748;
}

/* menu */

.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.header .menu-icon .navicon {
    background: #fff;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #fff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 5px;
}

.header .menu-icon .navicon:after {
    top: -5px;
}

/* menu btn */

.header .menu-btn {
    display: none;
}

.header .menu-btn:checked ~ .menu {
    max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
}

/* 48em = 768px */

@media (min-width: 800px) {
    .header li {
        float: left;
        justify-content: space-around;
    }

    .header li a {
        padding: 13px 25px;
    }

    .header .menu {
        clear: none;
        justify-content: space-around;
        max-height: none;
        max-width: 800px;
        margin: auto;
    }

    .header .menu-icon {
        display: none;
    }
}

/*dropdown menu bar*/
.header ul ul {
    display: none;
    position: absolute;
    top: 100%;
    background: #1D5980;
    padding: 0
}

.header ul ul li {
    float: none;
    width: 200px
}

.header ul ul a {
    line-height: 120%;
    padding: 10px 15px
}

.header ul li:hover > ul {
    display: block
}

.login-logo {
    padding: 2em 0 0.5em 0;
}

.logo-header {

}

/* Page content */
.content {
    padding: 16px;
}

.logo {
    display: inline;
    vertical-align: top;
    left: 10px;
    width: 90px;
    height: 65px;
    margin-right: 20px;
    fill: white !important;
    padding-left: 1em;
}

/*Landing*/
.landing-wrapper {
    width: 100%;
    /*padding-top: 60px;*/
    margin: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
}

.landing-wrapper h1 {
    color: white;
    width: 40%;
    font-weight: lighter;
    float: left;
    padding-top: 2em;
}

.landing-slider {
    color: white;
    float: left;
    height: 400px;
    margin-left: 5%;
    width: 40%;
}

.logoslider-wrapper {
    background-color: #1D5980;
    padding: 0 0 30px 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    margin-top: -5px;
}

.logoslider-wrapper > h2 {
    padding-bottom: 1em;
}

.logoslider {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-size: 40px;
    display: block;
    float: right;
    color: white;
    align-content: center;
}

.logoslider-container {
    padding-left: 4em;
}

.logoslider div {
    align-content: center;
    text-align: center;
    display: inline-block;
    margin-left: 50px;
}

/*footer*/
.footer {
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background-color: #FFFFFF;
    color: white;
    text-align: center;
}

.footer > div {
    padding: 0.5em 0;
}

.footer > div > a {
    padding: 5px;
}

/*404*/
.noref {
    padding: auto;

}

.parallax {
    background: no-repeat fixed;
    background-size: cover;
}

/*make landing responsive*/
@media screen and (max-width: 900px) {
    #landing-text {
        float: none;
        margin-right: 0;
        width: auto;
        border: 0;
        padding-bottom: 0;

    }

    #landing-image {
        min-height: 150px;
    }

    .flex-container > div {
        width: 48%;
    }

    .portfolio-container > div {
        width: 45%;
    }

    .item-cards > div {
        width: 46%;
    }

    .row-container > div {
        width: 100%;
    }

}

@media screen and (max-width: 600px) {
    .portfolio-container > div {
        width: 90%;
    }

    .item-cards > div {
        width: 90%;
    }

    .flex-container > div {
        width: 90%;
    }
}

/*services*/
.services-wrapper {
    background-color: white;
    text-align: center;
}

.services-wrapper h1 {
    margin-left: 0;
}

.services-wrapper p {
    padding: 1em;
}

.glitch {
    font-size: 100px;
    position: relative;
    width: 400px;
    margin: 0 auto;
}

.read-more-button {
    color: #4D429F;
}

.service-image {
    height: 150px;
    padding: 1em;
    max-width: 250px;

}

.internal-button {
    display: block;
    margin: auto;
    padding: 1em;
}

.hes-header {
    padding-top: 0.9em;
    margin-left: 0.5em
}

.hes-header-container {
    height: 126px
}


@media (max-width: 468px) {
    .hes-header {
        font-size: 1.4em;
        font-size: 5vw;
        padding-top: 1.25em;
    }

    .hes-logo {
        max-width: 6em;
    }

    .header {
        top: 105px
    }

    .hes-header-container {
        height: 105px
    }

    body {
        padding-top: 164px;
    }
}

.red-hover:hover {
    background-color: #EE2748 !important;
}

.grey {
    background-color: #4F4F51 !important;
}

.ui.grey.celled.inverted {
    background-color: #4F4F51 !important;

}

